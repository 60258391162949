<template>
  <div id="autoseo-chat" v-if="ll">
    <div class="autoseo-chat clearfix">
      <div class="chat-header clearfix">
        <div class="tabs-menu">
          <div data-tab="1" @click="tab=1" :class="[tab==1?'active':'']" data-ll="528">{{ll.i528||'How It Works'}}</div>
          <div v-if="$parent.project.package" data-tab="2" @click="tab=2;tabs2()" :class="[tab==2?'active':'']" data-ll="531">{{ll.i531||'Promotion history'}}</div>
        </div>
        <button class="autoseo-question autoseo-request" data-ll="527">{{ll.i527||'Have a question?'}}</button>
      </div>
    </div>
    <div class="tabs-content">
      <div v-if="tab==1" data-tab-index="1" class="active">
        <div class="autoseo-how-it-works">
          <div class="scroll-wrapper-2 no-scroll">
            <div class="autoseo-how-scroll-2">
              <div class="title" data-ll="528">{{ll.i528||'How It Works'}}</div>
              <div class="text" data-ll="3254" v-html="linkText(ll.i3254)"></div>
              <div class="text" data-ll="3255">{{ll.i3255||''}}</div>
              <div class="text" data-ll="3256">{{ll.i3256||''}}</div>
              <div class="text">
                <span class="italic" data-ll="3257" v-html="ll.i3257||''"></span>
              </div>
            </div>
          </div>
          <div class="autoseo-watch">
            <button class="autoseo-watch-video" @click="popupVideoObj.url='https://player.vimeo.com/video/369269710?autoplay=1';popupVideoObj.show=true;" data-video="https://player.vimeo.com/video/369269710" data-ll="529">
              {{ll.i529||'How to manage SEO campaign?'}}
            </button>
            <!-- data-video="//player.vimeo.com/video/173768899?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1&amp;api=1&amp;player_id=popup_video_iframe"  -->
          </div>
        </div>
      </div>
      <div v-if="tab==2" data-tab-index="2" class="active">
        <div class="autoseo-chat-messages">
          <div class="scroll-wrapper-2" id="autoseo-notes-scroll-1" :class="[message && message.length<4?'no-scroll':'']">
            <div class="autoseo-notes-scroll-2">
              <div class="autoseo-note" v-for="(row, index) in message"
                   :key="index"
                   :class="[row.vtype==3  ?'nfblock1':'']"
                   :data-id="row && row.data_attr && row.data_attr.id ? row.data_attr.id:null"
                   :data-project="row && row.data_attr && row.data_attr.project ? row.data_attr.project:null"
                   :data-nfsave="row && row.data_attr && row.data_attr.nfsave?row.data_attr.nfsave:null">
                <span class="autoseo-note-view "></span>
                <div class="autoseo-note-title">
                  <b v-html="row.title"></b>
                  <span class="autoseo-note-time" :data-ll-date="row.date">{{df(row.date)}}</span>
                </div>
                <div class="autoseo-note-body" data-ll-replace="" v-html="row.message"></div>
                <div class="v-show-hide" v-if="row.vtype==2">
                  <span v-if="!row.vdata.show" class="booster-note-show" data-ll="536" @click="showDetail(row.vdata, true)">{{ll.i536}}</span><!-- :data-work_ip="row.work_ip" :data-id="row.mes_arg" -->
                  <div v-if="row.vdata.show && row.vdata.position" class="autoseo-note-keys">
                    <div v-for="(row2, index2) in row.vdata.position" :key="index2">
                      <span class="font-size-16">{{ row2.keyword }}</span>
                      <span class="ks1 tooltips">{{ row2.val_old }}%<span data-ll="537">{{ll.i537||'Previous'}}</span></span>
                      <span class="ks2 tooltips">{{ row2.val }}%<span data-ll="538">{{ll.i538||'Current'}}</span></span>
                      <span class="ks3 tooltips" :class="[row2.change<=0?'red':'green']">{{ row2.change }}%<span data-ll="539">{{ll.i539||'Changes'}}</span></span>
                    </div>
                  </div>
                  <div v-if="row.vdata.show && row.vdata.position" class="booster-note-show pt-2" data-ll="535" @click="showDetail(row.vdata, false)">{{ll.i535}}</div>
                </div>
              </div>

              <!--
              <div class="autoseo-note "><span class="autoseo-note-view "></span>
                <div class="autoseo-note-title"><b>Start AutoSEO campaign</b> <span class="autoseo-note-time"
                                                                                    data-ll-date="1541679258000">Nov 8, 2018</span>
                </div>
                <div class="autoseo-note-body"><span data-ll-replace="3994||AutoSEO">Congratulations! AutoSEO promotion campaign for your website has been launched.</span><span
                    data-ll="3995">Your personal manager will select relevant visitor-generating keywords and start adding backlinks to your website pages shortly.</span><br><span
                    class="italic"><b data-ll="585">Note:</b> <span data-ll="3996">You can manage the backlinks distribution process personally.</span></span>
                  <a href="#" class="learn-more" data-ll="3997">Learn more about it.</a></div>
              </div>
              -->
            </div>
          </div>
        </div>
        <div class="buttons">
          <a v-if="reportLink" class="autoseo-download" :class="reportLinkShow?'':'grey'" :href="reportLinkShow?reportLink:'javascript:void(0);'" data-ll="385">{{ll.i385||'Download report'}}</a>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <popup-video :show="popupVideoObj.show" :obj="popupVideoObj"></popup-video>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import mixin from '../assets/js/mixin'
import PopupVideo from "./PopupVideo";

export default {
  name: "SeoMessage",
  components: {
    PopupVideo
  },
  computed:{
    lang(){
      return this.$root.lang;
    },
    ll(){
      return this.$parent.ll;
    }
  },
  data: function() {
    return {
      manager: null,
      seoCamp: {
        name:''
      },
      project:{
        idu:null,
        ids:null
      },
      tab: 1,
      scrollLoad: false,
      message: [],
      reportLink:null,
      reportLinkShow: true,
      popupVideoObj:{
        url:null,
        show: false
      },
      messageSet:{
        offset:0,
        limit: 100,
        page: 0
      },
      stopLoad: false,
      loadMessage: false
    }
  },
  methods: {
    async init(){
      this.project.ids = this.$parent.project.ids;
      this.project.idu = this.$parent.project.idu;
      this.seoCamp.name = 'AutoSEO';

      this.reportLink ='https://semalt.com/handlers/report_all.php?project='+this.project.ids;
      this.checkButtonReport();
    },
    async onScrollToBottom2(event){
      if(this.stopLoad || this.loadMessage ) return;

      if(event && event.path && event.path[0]){
        this.loadMessage = true;
        let block = event.path[0];
        let OFFSET = Math.round(block.scrollHeight * 0.2);
        //console.log(111111, block.scrollTop, block.scrollHeight, event.path[1].scrollHeight, OFFSET);
        if(block.scrollTop > block.scrollHeight - OFFSET) {  //block.clientHeight

          this.messageSet.page ++;
          let res = await axios.post('/api/booster/load_history', {ids:this.project.ids, page: this.messageSet.page});
          if(res && res.data && res.data.result ) {
            this.message = this.message.concat(res.data.result);
          }
          else{
            this.stopLoad = true;
          }
        }
        this.loadMessage = false;
      }
    },
    linkText(val){
      return val.replace('AutoSEO','<a target="_blank" href="/autoseo">AutoSEO</a>')
          .replace('FullSEO','<a target="_blank" href="/fullseo">FullSEO</a>');
    },
    seoName(val){
      return val.replace('%s',this.seoCamp.name);
    },
    async tabs2(){
      //if(this.message.length==0){}
      this.loadMessage = true;
      this.message = [];
      let res = await axios.post('/api/booster/load_history', {ids:this.project.ids });
      if(res && res.data && res.data.result ) {
        this.message = res.data.result;
      }
      this.loadMessage = false;

      //this.reportLinkShow = true;
      //if(!this.$parent.seoBlockOn) this.reportLinkShow = false;
      this.checkButtonReport();

      if(!this.scrollLoad){
        document.getElementById('autoseo-notes-scroll-1').addEventListener('scroll', this.onScrollToBottom2);
        this.scrollLoad = true;
      }
      let div = document.getElementById('autoseo-chat');
      if(div){
        div.addEventListener('click', this.learnMoreFunc, false);
      }
    },
    async showDetail(arr, show){
      if(arr){
        if(show){
          if(arr.position){
            arr.show = true;
          }
          else if(arr.id){
            let res = await axios.post('/api/booster/show_changes', {ids:this.project.ids, id:arr.id });
            if(res && res.data && res.data.result ) {
              arr.position = res.data.result;
            }
            arr.show = true;
          }
        }
        else{
          arr.show = false;
        }
      }
    },
    learnMoreFunc(target){
      if(target && target.path && target.path[0] && target.path[0].className){
        if(target.path[0].className=='learn-more'){
          this.tab=1;
        }
      }
      else if(target && target.target && target.target.className && target.target.className=='learn-more'){
          this.tab=1;
      }
    },
    checkButtonReport(){
        if(this.$parent.project.seoend){
            if(!this.$parent.project.active && this.$parent.project.seoend!=this.$parent.project.today) this.reportLink += '&today=' + this.$parent.project.seoend;
        }
        if(this.$parent.project.seostart ){

            if(this.$parent.project.seostart==this.$parent.project.today){
                this.reportLinkShow = false;
            }
            if(!this.$parent.seoBlockOn) this.reportLinkShow = false;
        }
        //console.log(11111, this.$parent.project.seostart, this.$parent.project.today, this.$parent.project)
    }
  },
  mixins: [mixin],
  mounted(){
    this.init();
  },
  destroyed() {
    if(this.scrollLoad) document.getElementById('autoseo-notes-scroll-1').removeEventListener('scroll', this.onScrollToBottom2);
    if(document.getElementById('autoseo-chat')) document.getElementById('autoseo-chat').removeEventListener('click', this.learnMoreFunc);
    //window.removeEventListener('mousemove', this.leftRightFunc);
  },
  watch:{
    lang(to){
      if(to) this.getll();
    },
    'project.ids'(to){
      if(to) this.project.ids = to;
    },
    'project.idu'(to){
      if(to) this.project.idu = to;
    },
    /*'$parent.project.seoend'(to){
      if(to){
        //console.log(this.$parent.project);
        if(!this.$parent.project.active && to!=this.$parent.today) this.reportLink += '&today=' + to;
        if(!this.$parent.seoBlockOn ) this.reportLinkShow = false;
        //|| this.$parent.project.seostart==this.$parent.project.today
      }
    },*/
    '$parent.project.seostart'(to){
      if(to){
          this.checkButtonReport();
      }
    }
  }
}
</script>

<style src="../../public/css/seosettings.css" lang="css" scoped></style>
<style lang="css" >
a{
  color: #63a5cf;
  text-decoration: none;
}
.booster-note-show,.booster-note-hide {
  text-decoration: none;
  color: #d94754;
  cursor: pointer;
}
.booster-note-hide {
  display: none;
}
.learn-more{
    cursor: pointer;
}
</style>
import { render, staticRenderFns } from "./SeoSettings.vue?vue&type=template&id=35380091&scoped=true&"
import script from "../assets/js/seosettings.js?vue&type=script&lang=js&"
export * from "../assets/js/seosettings.js?vue&type=script&lang=js&"
import style0 from "../../public/css/seosettings.css?vue&type=style&index=0&id=35380091&lang=css&scoped=true&"
import style1 from "./SeoSettings.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35380091",
  null
  
)

export default component.exports
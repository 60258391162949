/* eslint-disable */
import axios from 'axios'
//import clickOutside from '../../directive'
import seoMessage from "../../components/SeoMessage";
import IOdometer from 'vue-odometer';
import 'odometer/themes/odometer-theme-default.css';
import mixin from './mixin'
import SNotification from "../../components/SNotification"

export default {
	name: "SeoSettings",
	components: {
		seoMessage, IOdometer, SNotification
	},
	computed:{
		lang(){
			return this.$root.lang;
		}
	},
	data: function() {
		return {
			loadBlock: false,
			ll: null,
			manager: null,
			project:{
				idu:null,
				ids:null,
				idg: 0,
				offset:0,
				limit:100,
				top:null,
				kwfilter:'',
				sort:null,
				package: null,
				active: null,
				seostart:null,
				seoend:null,
				price:null,
				today: null,
				isElastic: false
			},
			loadKeyword: false,
			groups: [{id:0, name:'My keywords'}],
			group: null,
			topM:{
				id:null,
				name:'All rankings',
				ll: 500
			},
			sortM:{
				id:null,
				name:'Sort by:',
				ll: 492
			},
			keywords: null,
			keywordsTmp:[],
			settingsAll: 0,
			settingsAllCach: 0,
			settingsDomain:null,
			//scrollTop: 0,
			//xy:{x: 0, y:0},
			drag: {
				on:false,
				key:null,
				x:null,
				width:null,
				defSetting: 0,
				allSetting: 0,
				tStart: false,
			},
			showList:{
				keyword: false,
				top: false,
				sort: false
			},
			setFilterTr: false,
			timer2: false,
			scrollTmp: 0,
			seoSetting: {},
			buttonLoad: false,
			seoCamp: {
				name:'',
				nameShort:''
			},
			popupPay: {
				show: false,
				type: 2,
				link: null,
				price:null,
				odometer:100000
			},
			today:null,
			work: false,
			linkPerDayObj:[1,5,10,25,35,50,75,100,125,150,200,250,300,350,400,450,500,550,600],
			linkPerDay:50,
			allocateValue: 50,
			valSeoTr: false,
			fullseoSeoSettingsOn: false,
			seoBlockOn: true,
		}
	},
	methods: {
		async init(){
			//if(!this.loadBlock) return;
			if(this.$route.params && this.$route.params.idproject){
				this.project.ids = parseInt(this.$route.params.idproject);
			}
			await this.getll();
			if(this.ll.i644) this.groups[0].name = this.ll.i644;
			if(this.ll.i500) this.topM.name = this.ll.i500;
			if(this.ll.i492) this.sortM.name = this.ll.i492;
			this.group = this.groups[0];
			await this.get_info_site();

			await this.groups_list();
			if(document.getElementById('configurator-scroll')) document.getElementById('configurator-scroll').addEventListener('scroll', this.onScrollToBottom);
			this.project.offset = 0;
			this.start();

			if(this.popupPay.type==1){
				let res2 = await axios.post('/api/project/project_stat');
				if(res2 && res2.data) this.popupPay.odometer = parseInt(res2.data);
				setInterval(async ()=>{
					res2 = await axios.post('/api/project/project_stat');
					if(res2 && res2.data) this.popupPay.odometer = parseInt(res2.data);
				},5000);
			}

		},
		async getll(){
			let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["project","header-footer"]} });
			if(res && res.data && res.data.datall) {
				this.ll = res.data.datall;
			}
		},
		async groups_list(){
			let res = await axios.post('/api/keywords/groups_list', {ids:this.project.ids });
			if(res && res.data ) {
				if(res.data.groups){
					this.groups = this.groups.concat(res.data.groups);
				}
			}
		},
		async get_info_site(){
			let res = await axios.post('/api/project/get_info_site', {ids:this.project.ids });
			if(res && res.data ) {
				if(res.data.user && res.data.user.id){
					this.$root.user = res.data.user;
				}
				if(res.data.result && res.data.result.site){
					this.settingsDomain = res.data.result.site;
					this.project.ids = res.data.result.ids;
					if(res.data.result.seoblockoff){
						this.seoBlockOn = false;
					}
					else {
						this.loadBlock = true;
						this.$nextTick(()=>{
							this.initEvent();
						});
					}
					if(res.data.result.setting_all) this.settingsAll = this.settingsAllCach = Math.round(res.data.result.setting_all*10)/10;
					if(res.data.result.seosetting) this.seoSetting = res.data.result.seosetting;
					if(res.data.result.price) this.project.price = res.data.result.price;
					if(res.data.result.package){
						this.project.package = res.data.result.package;
						this.project.active = res.data.result.active;
						this.project.seostart = res.data.result.seostart;
						this.project.seoend = res.data.result.seoend;

						if(this.project.package=='fullseo' && res.data.result.seoblockoff ){
							this.seoBlockOn = false;
						}

					}

					if(this.project.package===null || !this.project.active ){
						this.popupPay.show = true;
						this.popupPay.type = 1;
						this.popupPay.price = this.project.price;
						this.seoCamp.name = 'AutoSEO';
						this.seoCamp.nameShort = 'Auto';

						let data = {
							manager:{
								id: 0,
								u_id: this.$root.user.id,
								u_email: this.$root.user.email,
								u_username: this.$root.user.username
							},
							package:'autoseo',
							month: 1,
							price: this.popupPay.price,
							project: [this.project.ids],
							button: 11 //11, 48, 49
						};

						if(this.project.package=='autoseo'){
							data.price = this.popupPay.price;
							data.button = 48;
							this.popupPay.type = 2;
						}
						else if(this.project.package=='fullseo'){
							this.seoCamp.name = 'FullSEO';
							this.seoCamp.nameShort = 'Full';
							data.package = 'fullseo';
							data.price = this.popupPay.price;
							data.button = 49;
							this.popupPay.type = 2;
						}
						this.popupPay.link = this.generateLinkPayMix(data);
					}
					if(res.data.result.linkPerDay) this.linkPerDay = res.data.result.linkPerDay;
				}
				if(res.data.today) this.today = res.data.today;
				if(res.data.work) this.work = res.data.work;
				if(res.data.fullseoSeoSettingsOn) this.fullseoSeoSettingsOn = true;
				if(res.data.booster_hide) this.loadBlock = false;
			}
		},
		generateLinkPrice(){

		},
		async start(){
			if(this.loadKeyword) return;
			this.loadKeyword = true;
			if(this.project.top!==null || this.project.sort=='1-100' || this.project.sort=='100-1') this.project.isElastic = true;
			else this.project.isElastic = false;

			await this.get_kw();
			this.loadKeyword = false;
			if(!this.project.isElastic && this.keywords.length>0 && !this.popupPay.show){
				await this.get_kw_pos();
			}
		},
		async get_kw(){
			if(this.project.offset==0){
				this.keywords = [];
				this.keywordsTmp = [];
			}
			//this.scrollTmp = document.getElementById('configurator-scroll').scrollTop;
			let res = await axios.post('/api/keywords/seo_setting_keywords',this.project);
			if(res && res.data ){
				if(res.data.result){
					this.keywords = this.keywords.concat(res.data.result);

					this.clearKwTmp();
				}
				this.settingsAll = this.settingsAllCach;
				if(res.data.today){
					this.project.today = res.data.today;
				}
			}
		},
		async get_kw_pos(){
			//let kw = this.keywords.filter(row => row.pos === null);
			let kw = [];
			this.keywords.forEach((item) => {
				if(item.pos===null) kw.push(item.kw);
			});
			let project = Object.assign({}, this.project, {keywords:kw});
			let jj;
			let res = await axios.post('/api/keywords/get_position_keyword', project);
			if(res && res.data ){
				if(res.data.result){
					res.data.result.forEach((item) => {
						if(item && typeof item.kw=='string'){
							jj = this.keywords.findIndex(row => item.kw.toLowerCase().trim()===row.kw.toLowerCase().trim() );
							if(jj>-1){
								this.keywords[jj].pos = item.pos;
								this.keywords[jj].pos_y = item.pos_y;
								this.keywords[jj].pos_s = item.pos_s;
								this.keywords[jj].diff = item.diff;
								this.keywords[jj].diff_s = item.diff_s;
								this.keywords[jj].yesterday = null;
								if(res.data.yesterday) this.keywords[jj].yesterday = res.data.yesterday;
							}
						}
					});
				}
			}
		},
		clearKwTmp(){
			this.keywordsTmp = [];
			for(let jj=0; jj<this.keywords.length; jj++){
				this.keywordsTmp[jj] = null;
				if(this.keywords[jj]) this.keywordsTmp[jj] = this.keywords[jj].seo;
			}
		},
		/*
		onDrag(index, event){
			//event.preventDefault();
			console.log('onDrag', this.drag.on, index, event.screenX, event);
			if(this.drag.on && event.screenX){
				let left = Math.round((event.screenX - this.drag.x)*100/this.drag.width * 10 )/10;
				if(left<0) left = 0;

				let allSetting = this.drag.allSetting - left + this.drag.defSetting;
				if(allSetting>=0) this.settingsAll = Math.round(allSetting*10)/10;
				else {
					left = this.drag.defSetting + this.drag.allSetting;
					this.settingsAll = 0;
				}
				this.keywords[index].seo = left;
				//console.log('#onDrag 222', left, this.drag.defSetting, this.settingsAll, event );
			}
		},
		dragStart(index, event){

			event.dataTransfer.clearData();
			event.dataTransfer.setData('Text', this.keywords[index]);
			//event.dataTransfer.setData('text/plain', null);
			//event.dataTransfer.setData('text/plain', event.target.dataset.item);
			//event.preventDefault();

			console.log('dragStart', index, event);
			if(this.project.package =='autoseo'){
				if(this.keywords[index].prm && this.keywords[index].prs===0){
					return;
				}
			}
			if(this.project.package =='fullseo' && !this.fullseoSeoSettingsOn  ){
				this.$refs.snotify.text(this.ll.i3339 || 'Ask for your managers` assistance to change the FullSEO campaign settings',1500);
				return;
			}

			this.drag.on = true;
			this.drag.key = index;
			let arr = document.getElementsByClassName('booster-slider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all')[index].getBoundingClientRect();
			this.drag.x = arr.x;
			this.drag.width = arr.width;
			this.drag.defSetting = this.keywords[index].seo;
			this.drag.allSetting = this.settingsAll;
			//console.log('#dragStart',index, event);
		},
		dragEnd(){
			console.log('dragEnd');
			this.drag.on = false;
			//console.log('#dragEnd');
		},
		dragOver(index, event){
			console.log('dragOver', index, event);
			event.preventDefault();
			event.dataTransfer.dropEffect = "none";

			//event.dataTransfer.effectAllowed = "none";
			//document.getElementsByClassName('ui-slider-handle')[index].style.cursor = "pointer";
			//console.log('#dragOver', event)
		},
		startChange(index){
			if(this.drag.tStart) return;
			this.drag.tStart = true;
			this.drag.defSetting = this.keywords[index].seo;
			this.drag.allSetting = this.settingsAll;
			console.log('#startChange', this.drag.defSetting, this.drag.allSetting );
		},
		*/
		changeRange(event, index, from){
			if(this.drag.tStart) return;
			this.drag.tStart = true;

			if(this.project.package =='fullseo' && !this.fullseoSeoSettingsOn && !this.work ){
				this.$refs.snotify.text(this.ll.i3339 || 'Ask for your managers` assistance to change the FullSEO campaign settings',1500);
				this.keywords[index].seo = this.keywordsTmp[index];
				this.drag.tStart = false;
				return;
			}

			let left = Math.round(this.keywords[index].seo * 10 )/10;
			if(left<0) left = 0;
			else if(left>100) left = 100;

			let allSetting = Math.round((this.settingsAll - left + this.keywordsTmp[index])*10)/10;
			if(allSetting>=0) this.settingsAll = allSetting;
			else {
				left = Math.round((this.keywordsTmp[index] + this.settingsAll)*10)/10;
				this.settingsAll = 0;
			}
			this.keywords[index].seo = left;
			this.keywordsTmp[index] = left;
			this.drag.tStart = false;
			//console.log('#changeRange', event, from, left, allSetting );
		},
		/*changeRange2(event, index, from){

			if(this.drag.tStart) return;
			this.drag.tStart = true;

			let left = Math.round(this.keywords[index].seo * 10 )/10;
			if(left<0) left = 0;
			else if(left>100) left = 100;

			let allSetting = Math.round((this.drag.allSetting - left + this.drag.defSetting)*10)/10;
			if(allSetting>=0) this.settingsAll = allSetting;
			else {
				left = Math.round((this.drag.defSetting + this.drag.allSetting)*10)/10;
				this.settingsAll = 0;
			}
			this.keywords[index].seo = left;
			if(from=='change') {
				//this.drag.defSetting = this.keywords[index].seo;
				//this.drag.allSetting = this.settingsAll;

			}
			this.drag.tStart = false;
			console.log('#changeRange', event, from, left, allSetting );
		},
		*/
		keydownFunc(event){
			console.log('#keydownFunc', event );
		},
		dragEnter(row, event){
			console.log('#dragEnter',row, event);
		},
		dragLeave(row, event){
			console.log('#dragLeave',row, event);
		},
		showListFunc(key){
			let val = this.showList[key];
			this.showList.sort = false;
			this.showList.top = false;
			this.showList.keyword = false;

			this.showList[key] = !val;
		},
		hideSortFunc(){
			this.showList.sort = false;
		},
		hideKeywordFunc(){
			this.showList.keyword = false;
		},
		hideTopFunc(){
			this.showList.top = false;
		},
		async setFilter(group,top,sort,text){
			if(this.setFilterTr) return;
			this.setFilterTr = true;
			if(group!==null){
				this.project.idg = group;
				let jj =  this.groups.findIndex(row => row.id == group);
				if(jj>-1) this.group = this.groups[jj];
			}
			if(top!==null){
				if(top===0) top=null;
				this.project.top = top;
				if(text && text[0] && text[1]){
					this.topM.id = top;
					this.topM.ll = text[0];
					this.topM.name = text[1];
				}
			}
			if(sort!==null){
				if(sort===0) sort=null;
				this.project.sort = sort;
				if(text && text[0] && text[1]){
					this.sortM.id = sort;
					this.sortM.ll = text[0];
					this.sortM.name = text[1];
				}
			}
			this.project.offset = 0;
			await this.start();
			this.setFilterTr = false;
		},
		changeKwFilter(){
			clearTimeout(this.timer2);
			this.timer2 = setTimeout(()=>{
				this.project.offset = 0;
				this.start();
			},800);
		},
		onScrollToBottom(){
			if(this.loadKeyword) return;

			let block = document.getElementById('configurator-scroll');
			let OFFSET = Math.round(block.scrollHeight * 0.2);
			//console.log(11111, block.scrollTop, block.scrollHeight, OFFSET );
			if(block.scrollTop > block.scrollHeight - OFFSET) {  //block.clientHeight
				this.project.offset += this.project.limit;
				if(this.project.isElastic && this.keywords && this.keywords.length < this.project.offset -10){
					console.log('#not_load', this.keywords.length, this.project.offset );
					//return;
				}
				this.start();
			}

		},
		clickButton(action, data){
			let req = {"action":action, "data":data};
			window.top.postMessage(JSON.stringify(req), '*' );
		},
		cancelFunc(){
			if(this.seoSetting){
				if(this.keywords && Array.isArray(this.keywords)){
					let ids = Object.keys(this.seoSetting);
					for(let jj in ids){
						ids[jj] = parseInt(ids[jj]);
					}
					for(let jj in this.keywords){
						if(ids.includes(this.keywords[jj].id)) this.keywords[jj].seo = this.seoSetting[this.keywords[jj].id];
						else this.keywords[jj].seo = 0;
					}
				}
			}
			else{
				if(this.keywords && Array.isArray(this.keywords)){
					for(let jj in this.keywords){
						this.keywords[jj].seo = 0;
					}
				}
			}
			this.settingsAll = this.settingsAllCach;
			this.clearKwTmp();
		},
		async saveFunc(){
			if(this.buttonLoad) return;
			this.buttonLoad = true;
			//this.scrollTmp = document.getElementById('configurator-scroll').scrollTop;
			let ids = Object.keys(this.seoSetting);
			for(let jj in ids){
				ids[jj] = parseFloat(ids[jj]);
			}
			for(let jj in this.keywords){
				if(ids.includes(this.keywords[jj].id) || this.keywords[jj].seo>0) this.seoSetting[this.keywords[jj].id] = parseFloat(this.keywords[jj].seo);
			}
			let res = await axios.post('/api/booster/save_seo_setting', {ids:this.project.ids, setting:this.seoSetting });
			if(res && res.data && res.data.result ) {
				await this.get_info_site();
				//if(res.data.setting) this.seoSetting = res.data.setting;
				let text = this.ll.i3338 || 'You have successfully changed AutoSEO campaign settings';
				if(this.project.package=='fullseo') text = text.replace('Auto','Full');
				this.$refs.snotify.text(text,1500);
			}
			//setTimeout(()=>{document.getElementById('configurator-scroll').scroll(0,this.scrollTmp);},100);
			this.buttonLoad = false;
		},
		async defaultFunc(){
			if(this.buttonLoad) return;
			this.buttonLoad = true;
			let res = await axios.post('/api/booster/save_def_seo_setting', {ids:this.project.ids });
			if(res && res.data && res.data.result ) {
				if(res.data.setting) this.seoSetting = res.data.setting;
				await this.get_info_site();

				let text = this.ll.i3338 || 'You have successfully changed AutoSEO campaign settings';
				if(this.project.package=='fullseo') text = text.replace('Auto','Full');
				this.$refs.snotify.text(text,1500);

				this.project.offset = 0;
				await this.start();
			}
			this.buttonLoad = false;
		},
		async setNullFunc(){
			if(this.buttonLoad) return;
			this.buttonLoad = true;
			for(let jj in this.keywords){
				this.keywords[jj].seo = 0;
			}
			let res = await axios.post('/api/booster/save_seo_setting', {ids:this.project.ids, setting:{}, empty:true });
			if(res && res.data && res.data.result ) {
				await this.get_info_site();
				this.seoSetting = {};
				this.$refs.snotify.text(this.ll.i3338.replace('Auto',''),1500);
			}
			this.clearKwTmp();
			this.buttonLoad = false;
		},
		linkText(val){
			return val.replace('AutoSEO','<a target="_blank" href="/autoseo">AutoSEO</a>')
				.replace('FullSEO','<a target="_blank" href="/fullseo">FullSEO</a>');
		},
		seoName(val){
			return val.replace('%s',this.seoCamp.name);
		},
		rtext(text, arr){
			return text.replace('%s',arr);
		},
		async linkPerDayFunc(){
			console.log(this.linkPerDay);
			let res = await axios.post('/api/booster/set_multiplier_user', {ids:this.project.ids, val:this.linkPerDay });
			if(res && res.data && res.data.result ) {
				this.$refs.snotify.text(this.ll.i3338.replace('Auto',''),1500);
			}
		},
		async allocateEvenlyFunc(){
			let res = await axios.post('/api/booster/allocate_evenly', {ids:this.project.ids, val:this.allocateValue,idg:this.project.idg });
			if(res && res.data && res.data.result ) {
				this.project.offset = 0;
				this.project.sort = null;
				this.project.top = null;
				this.project.kwfilter = '';
				this.seoSetting = {};
				await this.get_info_site();
				this.$refs.snotify.text(this.ll.i3338.replace('Auto',''),1500);
				await this.start();
			}
		},
		async unlockPremium(){
			let res = await axios.post('/api/booster/unlock_premium', {ids:this.project.ids });
			if(res && res.data && res.data.result ) {
				this.$refs.snotify.text(this.ll.i3338.replace('Auto',''),1500);
				this.project.offset = 0;
				await this.start();
			}
		},
		async lockPremium(){
			let res = await axios.post('/api/booster/lock_premium', {ids:this.project.ids });
			if(res && res.data && res.data.result ) {
				this.$refs.snotify.text(this.ll.i3338.replace('Auto',''),1500);
				this.project.offset = 0;
				await this.start();
			}
		},
		async unlockPremiumId(row){
			let row2 = [{id:row.id, val:row.prs}];
			let res = await axios.post('/api/booster/unlock_premium_id', {ids:this.project.ids, arr:row2 });
			if(res && res.data && res.data.result ) {
				this.$refs.snotify.text(this.ll.i3338.replace('Auto',''),1500);
			}
		},
		valSeoFunc(event, row){
			console.log( '#edit', event.target, event.target.innerText);
			clearTimeout(this.valSeoTr);
			this.valSeoTr = setTimeout(()=>{
				if(event && event.target && event.target.innerText){
					let left = Math.round(parseFloat(event.target.innerText)*10)/10;
					let allSetting = Math.round((this.settingsAll - left + row.seo) *10)/10;

					console.log( row.seo, left,  allSetting, this.settingsAll);
					if(allSetting>=0) this.settingsAll = Math.round(allSetting*10)/10;
					else {
						//this.settingsAll = 0;
						left = row.seo;
					}
					row.seo =  left; //event.path[0].innerText =
				}
				this.valSeoTr = false;
			},800);

			/* if(event && event.path && event.path[0] && event.path[0].innerText){
				let left = Math.round(parseFloat(event.path[0].innerText)*10)/10;
				let allSetting = Math.round((this.settingsAll - left + row.seo) *10)/10;

				console.log( row.seo, left,  allSetting, this.settingsAll);
				if(allSetting>=0) this.settingsAll = Math.round(allSetting*10)/10;
				else {
					//this.settingsAll = 0;
					left = row.seo;
				}
				row.seo =  left; //event.path[0].innerText =
			} */
		},
		initEvent(){
			/*
			console.log(document.getElementById('seo-settings') );
			document.getElementById('seo-settings').addEventListener('dragstart', (event) => {
				console.log(222, event);
				event.dataTransfer.setData('text/plain', null);
			});
			 */
		}
	},
	mixins: [mixin],
	mounted(){
		this.init();
		//window.addEventListener('mousemove', this.leftRightFunc);


	},
	destroyed() {
		document.getElementById('configurator-scroll').removeEventListener('scroll', this.onScrollToBottom);
		//window.removeEventListener('mousemove', this.leftRightFunc);

		//document.getElementById('seo-settings').removeEventListener('dragstart', this.dragStart);
	},

	watch:{
		lang(to, from){
			this.getll();
		},
		'project.kwfilter'(to){
			this.changeKwFilter();
		}

	}
}
<template>
  <div >
    <div class="modal-backdrop" v-if="show"></div>
    <div class="modal-popups" :style="{display:show?'table':'none'}">
      <div class="modal-popup-close" @click="obj.show = false;obj.url=null;"></div>
      <div class="modal-popup">
        <div class="popup_video">
          <div class="popup_video_iframe">
            <iframe v-if="obj && obj.url" :src="obj.url" width="800" height="450" id="popup_video_iframe" allowfullscreen  allow="autoplay; fullscreen" ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupVideo",
  data: function() {
    return {

    }
  },
  methods: {
    /*async init() {
      console.log(1111, this.show, this.obj)
    }*/
  },
  mounted(){
    //this.init();
  },
  props: {
    obj: Object,
    show: Boolean
  },
}
</script>

<style lang="css" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: #000;
  opacity: .9;
}
.modal-popups {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  text-align: center;
  width: 100%;
  height: 100%;
  display: table;
}
.modal-popup-close {
  position: absolute;
  z-index: 9999;
  background-image: url(//semalt.com/img/autoseo/x.png);
  background-repeat: no-repeat;
  width: 31px;
  height: 31px;
  right: 35px;
  top: 30px;
  cursor: pointer;
}
.modal-popup {
  display: table-cell;
  vertical-align: middle;
}
.popup_video {
  width: 800px;
  height: 480px;
  background-color: transparent;
  font-family: "Ebrima", "MyriadPro Light";
  font-weight: normal;
  display: inline-block;
  text-align: left;
}
#popup_video_iframe{
  width: 800px;
  height: 450px;
  border:0;
}

</style>